import React from 'react';
import './Nosotros.css';
import nosotrosImage from '../img/Nosotros.jpg';
import nosotrosImage2 from '../img/nosotros2.jpg'; // Ajusta la ruta según sea necesario

const Nosotros = () => {
  return (
    <div className="nosotros">
      <div className="section">
        <div className="text slide-in-bottom">
          <h1>Sobre Nosotros</h1>
          <p>
            Patitas felices inicia hace cuatro años con la motivación de controlar la fauna urbana de la ciudad de Quito 
            debido a la sobre población de caninos y felinos callejeros.
          </p> 
          <p>
            Somos un grupo de hogares temporales al norte y sur de Quito que fungimos como mamás de paso para aquellos perritos o gatitos que rescatamos de situaciones vulnerables o maltrato animal, los recuperamos y los ponemos en adopción.
          </p>
        </div>
        <div className="image slide-in-right">
          <img src={nosotrosImage} alt="Nosotros" />
        </div>
      </div>
      <div className="section">
        <div className="image slide-in-right">
          <img src={nosotrosImage2} alt="Nosotros 2" />
        </div>
        <div className="text slide-in-bottom">
          <h2>Nuestra principal misión</h2>
          <p>
            Es cambiar la vida de la mayor cantidad de estos angelitos sin voz, rescatarlos, curarlos, alimentarlos y buscarles un hogar para una vida digna.
          </p>
          <p>
            A la vez queremos sensibilizar a las personas en el DM de Quito sobre la tenencia responsable de animales de compañía.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nosotros;
