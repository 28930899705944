import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Slider.css';
import s1 from '../img/s1.jpeg';
import s2 from '../img/s2.jpeg';
import s3 from '../img/s3.jpeg';
import inicio3 from '../img/inicio3.jpeg';

const Slider = () => {
  const images = [s1, s2, s3];
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Cambio automático de slide cada 3 segundos
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  const handleButtonClick = (route) => {
    navigate(route);
  };

  return (
    <div className="main-container">
      <div className="slider-container">
        <div className="slider">
          {images.map((image, index) => (
            <div key={index} className={`slide ${currentSlide === index ? 'active' : ''}`}>
              <img src={image} alt={`slide-${index}`} className="image" />
              <div className="slide-content">
                {index === 0 && (
                  <>
                    <h1 className="title">Bienvenidos</h1>
                    <h2 className="title2">A la fundación Patitas Felices</h2>
                    <p className="subtitle">Quien salva una vida, salva al mundo entero</p>
                    <button className="adopt-button" onClick={() => handleButtonClick('/adopciones')}>ADOPTAR</button>
                  </>
                )}
                {index === 1 && (
                  <>
                    <h1 className="title">Fomentar</h1>
                    <h2 className="title2">Salva Vidas, Conviértete en un héroe.</h2>
                    <p className="subtitle">Participa en nuestros voluntariados.</p>
                    <button className="support-button" onClick={() => handleButtonClick('/voluntariados')}>Voluntariados</button>
                  </>
                )}
                {index === 2 && (
                  <>
                    <h1 className="title">Estamos en la</h1>
                    <h2 className="title2">Fundación Patitas Felices</h2>
                    <p className="subtitle">Elíge marcar la diferencia, alimenta una manada.</p>
                    <button className="donate-button" onClick={() => handleButtonClick('/donaciones')}>Dona Ahora</button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="dots-container">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${currentSlide === index ? 'active' : ''}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </div>

      <div className="info-section">
        <div className="info-text">
          <h2>Patitas Felices</h2>
          <p>
            Patitas Felices es una organización sin fines de lucro dirigida por personas que trabaja a nivel local
            para salvar la vidas de los animales en estado de calle y prevenir el sufrimiento y la crueldad de los mismos.
          </p>
          <p>
            Los esfuerzos locales de la fundación, que constituyen el 98% de su trabajo, se centran en el rescate de perros y gatos de alto riesgo en la ciudad de Quito
            Ayudando a estos perros a través del proceso de rehabilitación para colocarlos en adopción con éxito en sus hogares para siempre.
          </p>
        </div>
        <div className="info-image">
          <img src={inicio3} alt="Inicio" />
        </div>
      </div>

      <div className="support-section">
        <h2>Tu apoyo permite</h2>
        <h3>Crear segundas oportunidades</h3>
        <div className="columns">
          <div className="column">
            <p>Más de</p>
            <p className="number">300</p>
            <p>Esterilizaciones en campañas sociales</p>
          </div>
          <div className="column">
            <p>Más de</p>
            <p className="number">200</p>
            <p>animalitos en estado de calle desde el 2020</p>
          </div>
          <div className="column">
            <p>Más de</p>
            <p className="number">50</p>
            <p>apoyos quirúrgicos</p>
          </div>
          <div className="column">
            <p>Más de</p>
            <p className="number">2000</p>
            <p>comidas proporcionadas a perritos callejeros</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
