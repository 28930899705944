import React from 'react';
import './Footer.css';
import ifIcon from '../img/if.png';
import igIcon from '../img/ig.png';
import itIcon from '../img/it.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <h2>Síguenos en nuestras redes sociales:</h2>
        <div className="social-media">
          <div className="social-media-item">
            <a href="https://www.facebook.com/profile.php?id=100095376034798" target="_blank" rel="noopener noreferrer">
              <img src={ifIcon} alt="Facebook" />
              <span>Patitas Felices</span>
            </a>
          </div>
          <div className="social-media-item">
            <a href="https://www.instagram.com/patitasfelices.ecuador?igsh=MXh6N2ttN2UxNzc3cA==" target="_blank" rel="noopener noreferrer">
              <img src={igIcon} alt="Instagram" />
              <span>@patitasfelices.ecuador</span>
            </a>
          </div>
          <div className="social-media-item">
            <a href="https://www.tiktok.com/@patitasfelices.ecuador?_t=8nwTYuDOYQX&_r=1" target="_blank" rel="noopener noreferrer">
              <img src={itIcon} alt="TikTok" />
              <span>@patitasfelices.ecuador</span>
            </a>
          </div>
        </div>
        <div className="footer p">
        <p>&copy; 2024 Fundación Patitas Felices. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
