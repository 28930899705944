import React from 'react';
import './Adopciones.css';
import SliderAdopciones from './SliderAdopciones';
import FormularioAdopcion from './FormularioAdopcion';

const Adopciones = () => {
  return (
    <div className="adopciones-container">
      <section className="slider-section">
        <div className="slider-column">
          <div className="slider">
            <SliderAdopciones />
          </div>
        </div>
      </section>
      <section className="form-section">
        <div className="form-column">
          <FormularioAdopcion />
        </div>
      </section>
    </div>
  );
};

export default Adopciones;
