import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Voluntariados.css';
import v3 from '../img/v3.jpeg';
import v4 from '../img/v4.jpeg';

const Voluntariados = () => {
  const images = [v3, v4];
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    email: '',
    phone: '',
    availability: '',
    skills: ''
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:5000/send-voluntariado-email', formData);
      alert('Correo enviado con éxito');
    } catch (error) {
      alert('Error al enviar el correo');
    }
  };

  return (
    <div className="voluntariados-container">
      <section className="voluntariados-info">
        <div className="info-column">
          <div className="info-slider">
            <img src={images[currentSlide]} alt={`slide-${currentSlide}`} className="slider-image" />
          </div>
        </div>
        <div className="info-column">
          <div className="info-text">
            <h1>Oportunidades de Voluntariado</h1>
            <p>Únete a nosotros para marcar la diferencia en la vida de muchos animales. Como voluntario, tendrás la oportunidad de ayudar en diversas áreas, como el cuidado de los animales, las campañas de esterilización, y la organización de eventos.</p>
            <p>Si estás interesado en unirte a nuestro equipo de voluntarios.</p>
            <p className="subtitle">Completa el siguiente formulario y nos pondremos en contacto contigo.</p>
          </div>
        </div>
      </section>

      <section className="voluntariados-form">
        <div className="info-column">
          <h2 className="benefits-title">Beneficios del voluntariado</h2>
          <p className="benefits-p"> 
            <p>1. El voluntariado te conecta con los demás.</p>
            <p>2. El voluntariado es bueno para la mente y el cuerpo.</p>
            <p>3. El voluntariado puede hacer avanzar tu carrera.</p>
            <p>4. El voluntariado aporta diversión y satisfacción a tu vida.</p>
            <p>5. El voluntariado puede potenciar tus habilidades sociales.</p>
          </p>
        </div>
        <div className="info-column">
          <h2>Formulario de Inscripción</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nombre Completo</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="age">Edad</label>
              <input type="number" id="age" name="age" value={formData.age} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Teléfono</label>
              <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="availability">Disponibilidad</label>
              <input type="text" id="availability" name="availability" value={formData.availability} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="skills">Habilidades y Experiencia</label>
              <textarea id="skills" name="skills" rows="4" value={formData.skills} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Enviar</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Voluntariados;
