import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Slider from './Components/Slider';
import Nosotros from './Components/Nosotros';
import Adopciones from './Components/Adopciones';
import Voluntariados from './Components/Voluntariados';
import Donaciones from './Components/Donaciones';
import Contactanos from './Components/Contactanos';
import ScrollToTop from './Components/ScrollToTop'; // Importa el nuevo componente

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Incluye el componente aquí */}
      <Header />
      <Routes>
        <Route path="/" element={<Slider />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/adopciones" element={<Adopciones />} />
        <Route path="/voluntariados" element={<Voluntariados />} />
        <Route path="/donaciones" element={<Donaciones />} />
        <Route path="/contactanos" element={<Contactanos />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
