import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../img/logo.png'; // Asegúrate de que la ruta es correcta
import menuIcon from '../img/im.png'; // Asegúrate de que la ruta es correcta

const Header = () => {
  const handleMenuToggle = () => {
    const menuItems = document.getElementById('menu-items');
    if (menuItems.classList.contains('show')) {
      menuItems.classList.remove('show');
    } else {
      menuItems.classList.add('show');
    }
  };

  const handleMenuClick = () => {
    const menuItems = document.getElementById('menu-items');
    if (menuItems.classList.contains('show')) {
      menuItems.classList.remove('show');
    }
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Fundación Patitas Felices</h1>
      </div>
      <nav className="menu">
        <button className="menu-toggle" id="menu-toggle" onClick={handleMenuToggle}>
          <img src={menuIcon} alt="Menu Icon" className="menu-icon" />
        </button>
        <ul id="menu-items">
          <li><Link to="/" onClick={handleMenuClick}>Inicio</Link></li>
          <li><Link to="/nosotros" onClick={handleMenuClick}>Nosotros</Link></li>
          <li><Link to="/adopciones" onClick={handleMenuClick}>Adopciones</Link></li>
          <li><Link to="/voluntariados" onClick={handleMenuClick}>Voluntariados</Link></li>
          <li><Link to="/donaciones" onClick={handleMenuClick}>Donaciones</Link></li>
          <li><Link to="/contactanos" onClick={handleMenuClick}>Contáctanos</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
