import React from 'react';
import './Donaciones.css';
import di from '../img/dip.jpg';

const Donaciones = () => {
  return (
    <div className="donaciones-container">
      <section className="donaciones-header">
        <h1>Dependemos totalmente de las donaciones de los simpatizantes para llevar a cabo nuestro trabajo. Gracias por tu generosidad y apoyo.</h1>
      </section>

      <section className="donaciones-main">
        <div className="donaciones-image">
          <img src={di} alt="Donaciones" className="donaciones-full-image" />
        </div>
        <div className="donaciones-info">
          <h1>¿Deseas apoyar nuestra causa?</h1>
          <p>Estamos en Quito-Ecuador, puedes contactarnos al 0999925334 y visitarnos.</p>
          <p className="desktop-text">Puedes colaborar con balanceado, medicinas, avena, cobijas, pipetas, shampoo de mascotas y más o con transferencias a las cuentas indicadas en la imagen.</p>
          <p className="desktop-text">Las donaciones son vitales para que nuestra fundación continúe operando y ayudando a los animales en necesidad. Tu apoyo nos permite proporcionar alimentos, atención médica y un hogar seguro a los animales rescatados.</p>
          <p className="desktop-text"><strong><em>Gracias a tu generosidad, podemos seguir salvando vidas y creando un impacto positivo en la comunidad.</em></strong></p>
        </div>
      </section>

      <section className="donaciones-main-responsive">
        <h1>¿Deseas apoyar nuestra causa?</h1>
        <p>Puedes colaborar con balanceado, medicinas, avena, cobijas, pipetas, shampoo de mascotas y más o con transferencias a las cuentas indicadas en la imagen.</p>
        <p>Las donaciones son vitales para que nuestra fundación continúe operando y ayudando a los animales en necesidad. Tu apoyo nos permite proporcionar alimentos, atención médica y un hogar seguro a los animales rescatados.</p>
        <div className="donaciones-image">
          <img src={di} alt="Donaciones" className="donaciones-full-image" />
        </div>
        <p><strong><em>Gracias a tu generosidad, podemos seguir salvando vidas y creando un impacto positivo en la comunidad.</em></strong></p>
      </section>
    </div>
  );
};

export default Donaciones;
