import React, { useState } from 'react';
import './FormularioAdopcion.css';

const FormularioAdopcion = () => {
  const [activeSections, setActiveSections] = useState([]);

  const toggleSection = (section) => {
    setActiveSections(prevSections =>
      prevSections.includes(section)
        ? prevSections.filter(s => s !== section)
        : [...prevSections, section]
    );
  };

  return (
    <div className="form-container">
      <h1>Formulario para adoptar una mascota</h1>
      <form>
        <div className={`section ${activeSections.includes('datos') ? 'active' : ''}`} data-section-id="datos">
          <h2 onClick={() => toggleSection('datos')}>Datos de quien quiere adoptar</h2>
          <div className="section-content">
            <div className="form-group">
              <label>Nombres y Apellidos</label>
              <input type="text" name="nombres" />
            </div>
            <div className="form-group">
              <label>Cédula</label>
              <input type="text" name="cedula" />
            </div>
            <div className="form-group">
              <label>Es mayor de 23 años?</label>
              <input type="text" name="esMayor" />
            </div>
            <div className="form-group">
              <label>Dirección</label>
              <input type="text" name="direccion" />
            </div>
            <div className="form-group">
              <label>Correo electrónico</label>
              <input type="text" name="correo" />
            </div>
            <div className="form-group">
              <label>Teléfono</label>
              <input type="text" name="telefono" />
            </div>
          </div>
        </div>
        <div className={`section ${activeSections.includes('motivos') ? 'active' : ''}`} data-section-id="motivos">
          <h2 onClick={() => toggleSection('motivos')}>Motivos por los cuales quiere adoptar</h2>
          <div className="section-content">
            <div className="form-group">
              <label>Por qué desea adoptar al animalito?</label>
              <input type="text" name="motivoAdopcion" />
            </div>
            <div className="form-group">
              <label>Está consciente de que un animalito es una responsabilidad de alrededor de 15 años?</label>
              <input type="text" name="responsabilidad" />
            </div>
            <div className="form-group">
              <label>Tiene más mascotas en casa?</label>
              <input type="text" name="tieneMascotas" />
            </div>
            <div className="form-group">
              <label>Las personas con las que convive están de acuerdo en tener una mascota?</label>
              <input type="text" name="convivencia" />
            </div>
          </div>
        </div>
        <div className={`section ${activeSections.includes('condiciones') ? 'active' : ''}`} data-section-id="condiciones">
          <h2 onClick={() => toggleSection('condiciones')}>Condiciones socioeconómicas</h2>
          <div className="section-content">
            <div className="form-group">
              <label>Posee las condiciones económicas adecuadas para mantener al animalito? Trabajo estable?</label>
              <input type="text" name="condicionesEconomicas" />
            </div>
            <div className="form-group">
              <label>Posee algo propio: carro, departamento, casa, terreno? O cuenta con un arriendo estable?</label>
              <input type="text" name="posesiones" />
            </div>
            <div className="form-group">
              <label>Posee el espacio suficiente para que el animalito se sienta cómodo?</label>
              <input type="text" name="espacio" />
            </div>
            <div className="form-group">
              <label>En caso de que tenga que viajar o salga de casa: quién se haría cargo del animalito?</label>
              <input type="text" name="cuidadoAnimalito" />
            </div>
            <div className="form-group">
              <label>En caso de que tenga que viajar cuenta con familiares que puedan acogerlo o estaría dispuesto a pagar un hotel para mascotas?</label>
              <input type="text" name="viajeAnimalito" />
            </div>
          </div>
        </div>
        <div className={`section ${activeSections.includes('adopcion') ? 'active' : ''}`} data-section-id="adopcion">
          <h2 onClick={() => toggleSection('adopcion')}>Condiciones de adopción</h2>
          <div className="section-content">
            <div className="form-group">
              <label>Accede a cancelar el valor de $20 por el esquema de vacunas (primera vacuna) y desparasitaciones del animalito?</label>
              <input type="text" name="aceptarCosto" />
            </div>
            <div className="form-group">
              <label>Accede a enviarnos fotos constantes del animalito como pruebas de que se encuentre dentro de los parámetros de Bienestar animal?</label>
              <input type="text" name="enviarFotos" />
            </div>
            <div className="form-group">
              <label>Accede a firmar una carta de compromiso para asegurarnos que el animalito se encuentre siempre bajo los parámetros de bienestar animal, junto a una copia de su cédula, planilla de agua o luz?</label>
              <input type="text" name="cartaCompromiso" />
            </div>
          </div>
        </div>
        <div className={`section ${activeSections.includes('fotos') ? 'active' : ''}`} data-section-id="fotos">
          <h2 onClick={() => toggleSection('fotos')}>Fotos y Videos del lugar donde vivirá la mascota</h2>
          <div className="section-content">
            <div className="form-group">
              <label>Adjuntar a estas preguntas fotografías y videos del lugar (espacio, cuarto, sala) donde vaya a estar el animalito.</label>
              <input type="file" name="fotosVideos" />
            </div>
          </div>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default FormularioAdopcion;
