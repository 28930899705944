import React, { useState, useEffect, useCallback } from 'react';
import './SliderAdopciones.css';
import a1 from '../img/a1.jpeg';
import a2 from '../img/a2.jpeg';
import a3 from '../img/a3.jpeg';



const SliderAdopciones = () => {
  const images = [ a1, a2, a3,];
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Cambio automático de slide cada 3 segundos
    return () => clearInterval(interval);
  }, [nextSlide]);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="slider-container">
      <div className="slider">
        {images.map((image, index) => (
          <div key={index} className={`slide ${currentSlide === index ? 'active' : ''}`}>
            <img src={image} alt={`slide-${index}`} className="image" />
          </div>
        ))}


<div className="slider-text">
          <h1>¡Quieres ser parte de una <span className="highlight">historia de amor</span> de adopción?</h1>
          <h2>Llena el siguiente formulario:</h2>
        </div>
     

      </div>
      <div className="dots-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default SliderAdopciones;
