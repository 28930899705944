import React, { useState } from 'react';
import axios from 'axios';
import './Contactanos.css';
import p2 from '../img/p2.jpeg';
import ifIcon from '../img/if.png';
import igIcon from '../img/ig.png';
import itIcon from '../img/it.png';

const Contactanos = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:5000/send-contact-email', formData);
      alert('Correo enviado con éxito');
    } catch (error) {
      alert('Error al enviar el correo');
    }
  };

  return (
    <div className="contactanos-container">
      <section className="contact-header">
        <div className="header-content">
          <div className="left-column">
            <h1 className="header-title-orange">Gracias por ponerte en contacto con:</h1>
            <h1 className="header-title-green">Fundación Patitas Felices</h1>
            <p>¡Tu participación es muy apreciada y nuestros cachorros están ladrando de emoción! </p>
            <p>¡No podríamos salvar tantas vidas como lo hacemos sin el apoyo de nuestra comunidad!</p>
            <p>Espera al menos 48 horas para que respondamos a tus consultas. </p>
            <p>¡Trabajamos juntos como uno solo para los animales!</p>
          </div>
          <div className="right-column">
            {/* Columna vacía */}
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="contact-form-column">
          <h2>Envíanos un Mensaje</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Nombre Completo</label>
              <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Correo Electrónico</label>
              <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Asunto</label>
              <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Mensaje</label>
              <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} required></textarea>
            </div>
            <button type="submit">Enviar</button>
          </form>
        </div>

        <div className="contact-info-column">
          <h2>Contáctanos</h2>
          <p>Dirección: Quito, Ecuador</p>
          <p>Teléfono: 0999925334</p>
          <p>Correo Electrónico: contacto@fpatitasfelices.org</p>

          <h3>Síguenos en nuestras redes sociales:</h3>
          <div className="social-media">
            <div className="social-media-item">
              <a href="https://www.facebook.com/profile.php?id=100095376034798" target="_blank" rel="noopener noreferrer">
                <img src={ifIcon} alt="Facebook" />
                <span>Patitas Felices</span>
              </a>
            </div>
            <div className="social-media-item">
              <a href="https://www.instagram.com/patitasfelices.ecuador?igsh=MXh6N2ttN2UxNzc3cA==" target="_blank" rel="noopener noreferrer">
                <img src={igIcon} alt="Instagram" />
                <span>@patitasfelices.ecuador</span>
              </a>
            </div>
            <div className="social-media-item">
              <a href="https://www.tiktok.com/@patitasfelices.ecuador?_t=8nwTYuDOYQX&_r=1" target="_blank" rel="noopener noreferrer">
                <img src={itIcon} alt="TikTok" />
                <span>@patitasfelices.ecuador</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <h2>Nuestra Ubicación</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.67425412345!2d-78.4986829234!3d-0.180653452834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7f872b2ff4db93a9!2sPatitas%20Felices!5e0!3m2!1sen!2sec!4v1598398737637!5m2!1sen!2sec"
          width="100%"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          title="Patitas Felices"
        ></iframe>
      </section>
    </div>
  );
};

export default Contactanos;
